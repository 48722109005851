const initialState = {
 boxes: [
  {
   id: 0,
   label: "Camera1",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: true,
   editing: false,
   linked: false,
   colour: "blue",
  },
  {
   id: 1,
   label: "Camera2",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "green",
  },
  {
   id: 2,
   label: "Camera3",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "purple",
  },
  {
   id: 3,
   label: "Camera4",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "red",
  },
  {
   id: 4,
   label: "Camera5",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "orange",
  },
  {
   id: 5,
   label: "Camera6",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "lime",
  },
  {
   id: 6,
   label: "Camera7",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "pink",
  },
  {
   id: 7,
   label: "Camera8",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "teal",
  },
  {
   id: 8,
   label: "Camera9",
   position: {
    // x: 192,
    x: 384,
    // y: 108,
    y: 216,
   },
   calculatedPosition: {
    x: 0,
    y: 0,
   },
   cropped: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
   },
   lrCropLinked: true,
   tbCropLinked: true,
   scale: 0.5,
   show: false,
   editing: false,
   linked: false,
   colour: "yellow",
  },
 ],
 images: [],
 imageIsForeground: true,
 mode: "atem",
 snap: "1",
 guide: "1",
 copyFrom: null,
 setSources: true,
};

export default function rootReducer(state = initialState, action) {
 switch (action.type) {
  case "UPDATE_BOXES":
   return {
    ...state,
    boxes: state.boxes.map((w) => {
     if (w.id !== action.id) return w;

     return {
      ...w,
      ...action.update,
     };
    }),
   };

  case "UPDATE_BOXES_X":
   return {
    ...state,
    boxes: state.boxes.map((w) => {
     if (w.id !== action.id) return w;

     return {
      ...w,
      position: {
       ...w.position,
       x: action.update.position.x,
      },
      calculatedPosition: {
       ...w.calculatedPosition,
       x: action.update.calculatedPosition.x,
      },
     };
    }),
   };

  case "UPDATE_BOXES_Y":
   return {
    ...state,
    boxes: state.boxes.map((w) => {
     if (w.id !== action.id) return w;

     return {
      ...w,
      position: {
       ...w.position,
       y: action.update.position.y,
      },
      calculatedPosition: {
       ...w.calculatedPosition,
       y: action.update.calculatedPosition.y,
      },
     };
    }),
   };

  case "UPDATE_BOX_CROP":
   // return { ...state, ...action.update };
   return {
    ...state,
    boxes: state.boxes.map((w) => {
     if (w.id !== action.id) return w;

     return {
      ...w,
      cropped: {
       ...w.cropped,
       ...action.update,
      },
     };
    }),

    lrCropLinked: true,
    tbCropLinked: true,
   };

  case "ADD_IMAGE":
   // return { ...state, ...action.update };
   return {
    ...state,
    images: [
     {
      url: action.blob,
     },
    ],
   };

  case "TOGGLE_IMAGE_IS_FOREGROUND":
   return {
    ...state,
    imageIsForeground: !state.imageIsForeground,
   };
  case "REMOVE_IMAGE":
   return {
    ...state,
    images: [],
   };

  case "SET_MODE":
   return {
    ...state,
    mode: action.mode,
   };

  case "SET_SNAP":
   return {
    ...state,
    snap: action.snap,
   };

  case "SET_GUIDE":
   return {
    ...state,
    guide: action.guide,
   };

  case "SET_COPY_FROM":
   return {
    ...state,
    copyFrom: action.box,
   };
  case "SET_SOURCES":
   return {
    ...state,
    setSources: action.set,
   };

  default:
   return state;
 }
}
