import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
 apiKey: "AIzaSyDqIUpcjNL5RqJ-0PzVq8umu9ijOJLUB4M",
 authDomain: "h2r-supersource-builder.firebaseapp.com",
 projectId: "h2r-supersource-builder",
 storageBucket: "h2r-supersource-builder.appspot.com",
 messagingSenderId: "1032428556116",
 appId: "1:1032428556116:web:aa27b4fdf5330a645542c1",
 measurementId: "G-3LHYBGVR36",
};

firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
