export default {
 version: 2,
 type: "page",
 config: {
  1: { style: "pageup" },
  2: {
   style: "png",
   text: "Super Source",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
  },
  3: {
   style: "png",
   text: "",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
  },
  4: {
   style: "png",
   text: "",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
  },
  5: {
   style: "png",
   text: "",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
  },
  6: {},
  7: {},
  8: {},
  9: { style: "pagenum" },
  10: {
   style: "png",
   text: "1",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
   png64:
    "iVBORw0KGgoAAAANSUhEUgAAAEgAAAA6CAYAAAATBx+NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMjSURBVHgB7ZpNj9MwEIbfiZO2WrqVECwSWsQFLlz3zE/k73DlxokVf4AbCFYssLuw/U5iM+Ok2RS1mAN1cpinapsPq7Zfj8eZcQFFURRFURRF2QGdvHrrVo+fwYBQoluI384WgEn5oD5HdySwSEHSEkLB34kl9AHacxwb4uFJLYsjw5Twh6OuBXL+hap1nWNFF+rShvdC3c6tBmLDUf6KChRABQqgAgVQgQKoQAFUoAAqUAAVKEDPBCI0MUY/wkIOViMiYY3EfokPI2z7znY5iTN8mbt4w/FV8ndcVPGiCiTdTco1ZpeXVSRYQ651zC9JKohptyWU8+x4AjOZICZRBRITckUBt1w1AvmcT7nijAsLw9coMT79IhbTfKOyvHKw5LzVGDE9Q7JpgHOHt1tfg2udSNAuOhRruPkUxuXAeg4qc7h8gSZrVgf3VVvjus20qr+a21sdOAj1IPxZRzoCmREbFU+qLEHJ1kJphm1nU0kUwwVRq8pUTN1ame0Wh67abRJidS6VaqXKLNsUqA2mSt5Rkzurh499VcltNTgsjZG7ki2ILTZJ6mbRYc1Xft3lxtd1OjY4nTj8ZHc0XRUYDzIUeYmbNXDMdm0z4inncLNwePn0CK8/zL2fMibeFJMBSmvDRYxs6537cXj+6AjvP17jjDt/djLC5S0LdkQYDkY4v7jA/fHEC/TlOmdHmaJxkb6xiIJUFXcVa9V8/nmGFw+H+PELeDNd4mqeICOLoZkitwbL7ws8GAHfbrl4umhWs9gPkNGfg2QWy5I9W1m8+yq9tbUv4uWfJ2GzULBYn6Zywr5oVflHSuI/+EcVSJ6g7WCIe6dPeH7bnWUcO+FdQogFmSyFRVzihhoQkXhFytKdW0z+SskS1I7Ybd1zfmMzdogWVaBqX7Lq4r6OkrcR45f0bQ2pk/i1Z9F8a+OwJ2g+KIAKFEAFCqACBVCBAqhAAVSgACpQABUoQCVQT/agtujPvliV/yTXhxZRlfLoSbQh1pNOZhb2KucQ0e3JKkouuIw2oLL1k5jcB6s72+L5Hwr+y5+Me/kHTkVRFEVRFKUP/AaIPwUHw6Nx1gAAAABJRU5ErkJggg==",
  },
  11: {
   style: "png",
   text: "2",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
   png64:
    "iVBORw0KGgoAAAANSUhEUgAAAEgAAAA6CAYAAAATBx+NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALnSURBVHgB7ZlLb9NAFIXPHU8NcQiPQhQVJKTCBhZI8Ivhp7BCYsUGVSxYIFURCLpIE0iT2J5hZvxK06TDohlb4n6SEz/G8p3jefieARiGYRiGYRhmCzR8+07nR6fQpKHdKY1WyRUQCXQFaX9UKQ7ZA01oFff8tRhafl/SxmM3qgJpWZ8mkOoYrVK0ZX01rragqgV3JJ7udPaOwgJ5YIE8sEAeWCAPLJAHFsgDC+SBBfLQXYHaTnlKggpk05kiKSa3T1tSHFWVBTbK2KRRmPvDvlOJgLikWCnMzybQqaqdg8uNxToL5M6ta2eP4zsJxP0+QhJWILOR8XvUfGn+qREgz12S6qQhKhUr/jU18q3EAr3WBArQ54tWQUU3We9bywzKCCfjGHm6AsXSXVbSlJNrgZG9WwVN9G0IpVGGvbPZbWqMIJG5mmkF0TsoRDRd8dJwo3fs7xmpbZM3wQgVwEy0g7R5XvVCqDypDyLkblcgr2MQqL1F3dyfW+F0uElOQphA7LbhdO4L0oW9O0we4OXgEOOLCVZphttxAspS/FjM8DDuIRWEW0awWbbEm6Pn+Dj+jN92WBIi6BeAbMeCJrwaPcXJ+AuejY7xuj/AUvcQqwscHz7Gh9OvuNdPMF0sMewN8H01Q+paToTQBJ3FGrQR4QQv7o4wnU3x/vwn/igz8pjZLPn1zYzZGZbnGkkUoz89Q9G82/FggwqkTD2F6Tq2vqt8hU+TsZvTqg9Bu/RkZvJ6hcWVt+OW+8LUbjXIjttBZzEExE0EppbJk0emtWB7TdXGulhZxoknRfB2FLaLVd+AMoLe9WQzjVF0VYi2OllLY9A1E2apAml0ImHtXDbfkSS+hv0gDyyQBxbIAwvkgQXywAJ5YIE8sEAeWCAPvC7mQRZ2gzXRbXp9TUp4A5niv5hzmqwlW5gaux4ZykO3yOEkh6C5sxMiJXZWgm7ijWrvCWfWC9F4Idt0oM5lbAzDMAzDMMx/x18Jhfb3gYs8+QAAAABJRU5ErkJggg==",
  },
  12: {
   style: "png",
   text: "3",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
   png64:
    "iVBORw0KGgoAAAANSUhEUgAAAEgAAAA6CAYAAAATBx+NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALzSURBVHgB7ZrNbtNAEMf/u147H4SWUiEBEioSCHHggngYHoG3RBwRDwAXDgghwalF0NI08e4wu3ZSt02yQS3TSJ2flcRfyXr/np2ZHQdQFEVRFEVRlAWYN68/0J3hYxAvS0/iBSuOr9lU+1oNUYA1pm3NLPiNSMDlyV+L5XbczmSEe+VWEmi5BJcVZ31CYIGsxSZg4OEa21gtz01mM27VBqMCZVCBMqhAGVSgDCpQBhUogwqUQQXKoAJlcBAkTUGp4BXPa3bx5Kad0xLNNmb7Awx1T5JBVKAkEXe0KQ5Qp8Pzw50POp0jJ8G6xi43bxQVKHBHLb+FE16nxZ0k3k+WWgtqzzGNaEXJ37Uekp5Bdohxr/0U+PX9sFGrJcow30rWxRUoMxPp9Jz+donhbiVadxAeYmicC521gOl03KkBsXeyDhTrQsaxUB1/cxV1sn9kI6JY8MSvkMwkBGo/r0GNBTh0ipv/23STMZimpSZYWcR41huMYKnmUUcoU5Rj6ykGTREvWpzExS2C23bRKca7JVJRNLEtk3xMHGXl1gnsUQlfT+B5iFneSSygIZPOLfjabBUzgyn8cZUuOBBLSjJhPqYlzhibxr9MyZXmec6Tl4TfPz3+9GvsPCAMhgElLwf7QDWq2bYcJuMp6LCH3WdjfHwbLatgv1RASJ8kkLAPmvWMcHTs4dkiHj0nPH3FNwkVij7h4R4PPzeGGxXYe+GwvcuRr46xJJz7DRlko9g8EbT48Yl9z7CHg2+Ez+/ZfkMPxDlA9EO2uMv5EuFrVTXDEX1ODwILaMRdkaxAMb/hFu2Au+ktpnWRfE1y2ZYthMO7CyZtx0PB+0ag6JcGBmW/gDTCeRBFN4Lb929dOBLHegoV7IRTTkTnHx5e5UPD9ZFPFBMXB8qs2zFCnY1StPQ7Emi5I4MKlEEFyqACZVCBMqhAGVSgDCpQBhUogwqUQQXKoAJlUIEyuP3jL+8m9RE2hfhQw8oWDVeg//xVFEVRFOXm8hdUNgff6l67WwAAAABJRU5ErkJggg==",
  },
  13: {
   style: "png",
   text: "4",
   size: "auto",
   alignment: "center:center",
   pngalignment: "center:center",
   color: 16777215,
   bgcolor: 0,
   latch: false,
   relative_delay: false,
   png64:
    "iVBORw0KGgoAAAANSUhEUgAAAEgAAAA6CAYAAAATBx+NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMUSURBVHgB7Zq/bhNBEId/s7t3sRPHSkACihQpqIACpaTgHXg0RMUL8BxUdIiGAoGUBgnEn4igoBD7bneZ2bNjQhwvhb2JYL7Iyd3m7Nv7bnbmbs+AoiiKoiiKosyBnj56HPealyDYCzeK/LOUncUIIpquzd3GRw9LNvNJy+nP3+B2jmvcGYmCsGC/y+zQ4s9qY0BFJRUsxtFpfMR0Ti+7Y4SrhYGyEBWUQQVlUEEZVFAGFZRBBWVQQRlUUAYVlMGhIBRpuoB40f0WNwZa9H55BZSiqCAhTuRI6M71ELt2OtdMSWzpm8WigoKJMGwnjj3aIAd7/miliWw4HyTsx1UWsXBSKD7EQtvi8OMBYjtrjzxHlOaK0nIXJIbXgsRNnG4D1Bs1Nm9soSSFBU0C44/oOG6bJMnwK3Jk1RwmIiQYjx5P5KXhJg0exadkiuegecgkGR87qzAIwfMQc/yXh6OINJ2Qy5qncmnHMg0aaWlTqxfCOSj6WSWThMKxgmFV879Mql4k7bwdGZOksT4WxcvJlvSvbAw5yNlrQ7GZPIkMyTZEa2iu9+C+H7EDzjbk2YudiBMZMkvOfWNR2N4EHf5IJ9D7gNPEVAAn0WOslZO2+giSQA1dMg53b3M1+4ZRvwbt3gK2rgGjFvTpK5qBQ11V+Pn5CIO1CvRwD/7JM9EKa83q+/kbjtJuQ0qSK99vKlHdMLEuoAlroHu7iDtD1O8bXj9C/8F9HL/dx/rGGsxgCPDw818OgPUBJ+lxuo4qmZDOJOnVj26JHrnQ4aHz6g3sYB307gNnZx46PKzqpsXJi9fY5CTtmzFq6RFHXKpj4wboVyhN2SrGZ99Yjp4eJ2XJJe2IS7sEle1uMeoK/ba7iHSpaEgep3TifM/C9coX3bJ7nNxjDG9up9VUN2mac7sqKg8OK35wKE2TKt9dP02ryL98qzEjzn7HM2voRJ0t6PESH5bpdEcGFZRBBWVQQRlUUAYVlEEFZVBBGVRQBhWUQQVlUEEZVFAGtz86eD44OenWrsB3b9PTjavwdVtFURRFUZT/nF+C5xu8qZbQpwAAAABJRU5ErkJggg==",
  },
  14: {},
  15: {},
  16: {},
  17: { style: "pagedown" },
  18: {},
  19: {},
  20: {},
  21: {},
  22: {},
  23: {},
  24: {},
  25: {},
  26: {},
  27: {},
  28: {},
  29: {},
  30: {},
  31: {},
  32: {},
 },
 instances: {
  uYw0Z0fJm: {
   instance_type: "bmd-atem",
   product: "ATEM",
   label: "atem",
   _configIdx: 0,
   modelID: 16,
   presets: 0,
   fadeFps: 10,
   host: "192.168.10.240",
   import_to: "uYw0Z0fJm",
  },
  "bitfocus-companion": {
   instance_type: "bitfocus-companion",
   label: "internal",
   id: "bitfocus-companion",
   _configIdx: 2,
  },
 },
 actions: {
  1: [],
  2: [
   {
    id: "O5wIzxaPG",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 6000 },
    delay: 0,
   },
   {
    id: "PwERC8sv_",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 97, action: "run" },
    delay: 100,
   },
  ],
  3: [
   {
    id: "Xhdvncefl",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 6000 },
    delay: 0,
   },
   {
    id: "l0Ne0bLjr1",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 98, action: "run" },
    delay: 100,
   },
  ],
  4: [
   {
    id: "yf5BMmBnq",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 6000 },
    delay: 0,
   },
   {
    id: "xyCudtlsxw",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 99, action: "run" },
    delay: 100,
   },
  ],
  5: [
   {
    id: "OZgBmon5O",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 6000 },
    delay: 0,
   },
   {
    id: "NKFajntBoe",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 100, action: "run" },
    delay: 100,
   },
  ],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [
   {
    id: "fxz-ovYFu",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 93, action: "run" },
    delay: 0,
   },
   {
    id: "leEcFzHpD",
    label: "uYw0Z0fJm:setSsrcBoxSource",
    instance: "uYw0Z0fJm",
    action: "setSsrcBoxSource",
    options: { boxIndex: 0, source: 1 },
    delay: 0,
   },
   {
    id: "i4JSgAml-",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 1 },
    delay: 250,
   },
   {
    id: "kOK2cGpka",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "Super Source", page: "0", bank: 2 },
    delay: 0,
   },
   {
    id: "PnbLFlraK",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 3 },
    delay: 0,
   },
   {
    id: "k1Hp9kKUD",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 4 },
    delay: 0,
   },
   {
    id: "c9-HcN-YJ",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 5 },
    delay: 0,
   },
  ],
  11: [
   {
    id: "ZsKH5wnko",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 94, action: "run" },
    delay: 0,
   },
   {
    id: "AFvMf-uDN",
    label: "uYw0Z0fJm:setSsrcBoxSource",
    instance: "uYw0Z0fJm",
    action: "setSsrcBoxSource",
    options: { boxIndex: 1, source: 2 },
    delay: 0,
   },
   {
    id: "oE0NAKODI",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 2 },
    delay: 250,
   },
   {
    id: "w6UM-HE0m",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 2 },
    delay: 0,
   },
   {
    id: "I2sBdSri4",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "Super Source", page: "0", bank: 3 },
    delay: 0,
   },
   {
    id: "RDJW5RwQP",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 4 },
    delay: 0,
   },
   {
    id: "8fqFBioqj",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 5 },
    delay: 0,
   },
  ],
  12: [
   {
    id: "RgpezM7It",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 95, action: "run" },
    delay: 0,
   },
   {
    id: "jaAhabAh",
    label: "uYw0Z0fJm:setSsrcBoxSource",
    instance: "uYw0Z0fJm",
    action: "setSsrcBoxSource",
    options: { boxIndex: 2, source: 3 },
    delay: 0,
   },
   {
    id: "MKcuss0EWW",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 3 },
    delay: 300,
   },
   {
    id: "bSbFFa_LV",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 2 },
    delay: 0,
   },
   {
    id: "8MvkcL6xl",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 3 },
    delay: 0,
   },
   {
    id: "8qOPpjj5Y",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "Super Source", page: "0", bank: 4 },
    delay: 0,
   },
   {
    id: "hLpo7vLnT",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 5 },
    delay: 0,
   },
  ],
  13: [
   {
    id: "dDTVp3Cqp",
    label: "uYw0Z0fJm:macrorun",
    instance: "uYw0Z0fJm",
    action: "macrorun",
    options: { macro: 96, action: "run" },
    delay: 0,
   },
   {
    id: "GAJSjsgaj",
    label: "uYw0Z0fJm:setSsrcBoxSource",
    instance: "uYw0Z0fJm",
    action: "setSsrcBoxSource",
    options: { boxIndex: 3, source: 4 },
    delay: 0,
   },
   {
    id: "vwIbfzgE6a",
    label: "uYw0Z0fJm:program",
    instance: "uYw0Z0fJm",
    action: "program",
    options: { mixeffect: 0, input: 4 },
    delay: 300,
   },
   {
    id: "eL60Hg2uE",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 2 },
    delay: 0,
   },
   {
    id: "ZffPmHSiA",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 3 },
    delay: 0,
   },
   {
    id: "5NsjmvvNX",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "", page: "0", bank: 4 },
    delay: 0,
   },
   {
    id: "HAKlv8J1Q",
    label: "bitfocus-companion:button_text",
    instance: "bitfocus-companion",
    action: "button_text",
    options: { label: "Super Source", page: "0", bank: 5 },
    delay: 0,
   },
  ],
  14: [],
  15: [],
  16: [],
  17: [],
  18: [],
  19: [],
  20: [],
  21: [],
  22: [],
  23: [],
  24: [],
  25: [],
  26: [],
  27: [],
  28: [],
  29: [],
  30: [],
  31: [],
  32: [],
 },
 release_actions: {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
  15: [],
  16: [],
  17: [],
  18: [],
  19: [],
  20: [],
  21: [],
  22: [],
  23: [],
  24: [],
  25: [],
  26: [],
  27: [],
  28: [],
  29: [],
  30: [],
  31: [],
  32: [],
 },
 page: { name: "H2R Layouts" },
 feedbacks: {
  2: [
   {
    id: "WCwQqg-f8S",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 6000 },
   },
  ],
  3: [
   {
    id: "h6ufKJb3iR",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 6000 },
   },
  ],
  4: [
   {
    id: "ERr6nCakhF",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 6000 },
   },
  ],
  5: [
   {
    id: "KBgpvUC3zg",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 6000 },
   },
  ],
  10: [
   {
    id: "m3Ht4w0Pen",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 1 },
   },
   {
    id: "-6m-8Bp03U",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 0, input: 6000 },
   },
  ],
  11: [
   {
    id: "gE0OEEgvUn",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 8 },
   },
   {
    id: "z3gYG39g5d",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 0, input: 6000 },
   },
  ],
  12: [
   {
    id: "onFKHWwlAg",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 3 },
   },
   {
    id: "qcUcxFBQzS",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 0, input: 6000 },
   },
  ],
  13: [
   {
    id: "oy1OLB8sfw",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 16711680, input: 4 },
   },
   {
    id: "HdJ8S1sDzH",
    type: "program_tally",
    instance_id: "uYw0Z0fJm",
    options: { fg: 16777215, bg: 0, input: 6000 },
   },
  ],
 },
};
