import store from "./store";

export const updateBoxes = (id, update) => {
 return store.dispatch({
  type: "UPDATE_BOXES",
  id,
  update,
 });
};
export const updateBoxesX = (id, update) => {
 return store.dispatch({
  type: "UPDATE_BOXES_X",
  id,
  update,
 });
};
export const updateBoxesY = (id, update) => {
 return store.dispatch({
  type: "UPDATE_BOXES_Y",
  id,
  update,
 });
};

export const updateBoxCrop = (id, update) => {
 return store.dispatch({
  type: "UPDATE_BOX_CROP",
  id,
  update,
 });
};

export const addImage = (blob) => {
 return store.dispatch({
  type: "ADD_IMAGE",
  blob,
 });
};

export const toggleImageIsForeground = () => {
 return store.dispatch({
  type: "TOGGLE_IMAGE_IS_FOREGROUND",
 });
};

export const removeImage = () => {
 return store.dispatch({
  type: "REMOVE_IMAGE",
 });
};

export const setMode = (mode) => {
 return store.dispatch({
  type: "SET_MODE",
  mode,
 });
};

export const setSnap = (snap) => {
 return store.dispatch({
  type: "SET_SNAP",
  snap,
 });
};

export const setGuide = (guide) => {
 return store.dispatch({
  type: "SET_GUIDE",
  guide,
 });
};

export const setCopyFrom = (box) => {
 return store.dispatch({
  type: "SET_COPY_FROM",
  box,
 });
};

export const setSources = (set) => {
 return store.dispatch({
  type: "SET_SOURCES",
  set,
 });
};
