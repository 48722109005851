import { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";

import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import YouTube from "react-youtube";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import imageToBase64 from "image-to-base64/browser";
import domtoimage from "dom-to-image";

import { analytics } from "./store/firebase";
import Modal from "./Modal";
import {
 updateBoxes,
 updateBoxesX,
 updateBoxesY,
 updateBoxCrop,
 addImage,
 removeImage,
 toggleImageIsForeground,
 setMode,
 setSnap,
 setGuide,
 setCopyFrom,
 setSources,
} from "./store/actions";
import companionPage from "./companionPage";
import SourcesCombobox from "./SourcesCombobox";

import grid32x18 from "./img/grid-32x18.png";
import grid16x9 from "./img/grid-16x9.png";
import grid16 from "./img/grid-16.png";
import grid8 from "./img/grid-8.png";
import grid4 from "./img/grid-4.png";
import gridC from "./img/grid-c.png";
import gridCGFX from "./img/grid-c-gfx.png";
import gridCAction from "./img/grid-c-action.png";

import packPreview from "./img/pack-preview.jpg";
import packPreviewVmix from "./img/pack-preview-vmix.jpg";
import vmixImg1 from "./vMixImg/1.png";
import vmixImg2 from "./vMixImg/2.png";
import vmixImg3 from "./vMixImg/3.png";
import vmixImg4 from "./vMixImg/4.png";
import vmixImg5 from "./vMixImg/5.png";
import vmixImg6 from "./vMixImg/6.png";
import vmixImg7 from "./vMixImg/7.png";
import vmixImg8 from "./vMixImg/8.png";
import vmixImg9 from "./vMixImg/9.png";
import vmixImgBack from "./vMixImg/back.png";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const videoRatios = [
 {
  width: 128,
  height: 72,
 },
 {
  width: 256,
  height: 144,
 },
 {
  width: 384,
  height: 216,
 },
 {
  width: 512,
  height: 288,
 },
 {
  width: 640,
  height: 360,
 },
 {
  width: 768,
  height: 432,
 },
 {
  width: 896,
  height: 504,
 },
 {
  width: 1024,
  height: 576,
 },
 {
  width: 1152,
  height: 648,
 },
 {
  width: 1280,
  height: 720,
 },
 {
  width: 1408,
  height: 792,
 },
 {
  width: 1536,
  height: 864,
 },
 {
  width: 1664,
  height: 936,
 },
 {
  width: 1792,
  height: 1008,
 },
 {
  width: 1920,
  height: 1080,
 },
];

const snapGrids = [
 {
  label: "Very precise",
 },
 {
  label: "Medium",
  grid: [4, 2.25],
 },
 {
  label: "Course",
  grid: [8, 4.5],
 },
];

const guides = [
 {
  label: "None",
 },
 {
  label: "Center",
  gridImage: gridC,
 },
 {
  label: "Center + GFX",
  gridImage: gridCGFX,
 },
 {
  label: "Center + Action safe",
  gridImage: gridCAction,
 },
 {
  label: "4x4",
  gridImage: grid4,
 },

 {
  label: "8x8",
  gridImage: grid8,
 },
 {
  label: "16x9",
  gridImage: grid16x9,
 },
 {
  label: "16x16",
  gridImage: grid16,
 },
 {
  label: "32x18",
  gridImage: grid32x18,
 },
];

function App() {
 return (
  <Router>
   <div>
    {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
    <Switch>
     <Route path="/support">
      <Support />
     </Route>
     <Route path="/tutorial">
      <Tutorial />
     </Route>
     <Route path="/">
      <Home />
     </Route>
    </Switch>
   </div>
  </Router>
 );
}

function Home() {
 // ATEM STATIC
 const [finalMacro, setFinalMacro] = useState("");
 const [macroIndex, setMacroIndex] = useState(0);
 const [superSourceIndex, setSuperSourceIndex] = useState(0);
 const [macroName, setMacroName] = useState("SuperSource Macro");
 const [macroDescription, setMacroDescription] = useState(
  "Created with H2R Layouts"
 );
 const [xmlModalOpen, setXmlModalOpen] = useState(false);
 const setSourcesBoolean = useSelector((state) => state.setSources);
 const [lockX, setLockX] = useState(false);
 const [lockY, setLockY] = useState(false);

 // ANIMATED ATEM SUPERSOURCE
 const [finalMacroATEMAnimated, setFinalMacroATEMAnimated] = useState("");
 const [macroIndexATEMAnimated] = useState(92);
 const [macroNameATEMAnimated] = useState("Animated SuperSource Macro");

 const [showGuides] = useState(true);
 const [mainStageHovered, setMainStageHovered] = useState(false);
 const boxes = useSelector((state) => state.boxes);
 const images = useSelector((state) => state.images);
 const imageIsForeground = useSelector((state) => state.imageIsForeground);
 const mode = useSelector((state) => state.mode);
 const snap = useSelector((state) => state.snap);
 const guide = useSelector((state) => state.guide);

 const mainStage = useRef();
 const boxesRef = [useRef(), useRef(), useRef(), useRef()];
 const macroRef = useRef();
 const macroRefATEMAnimated = useRef();

 // VMIX
 const [finalvMixConfig, setFinalvMixConfig] = useState("");
 const [finalvMixConfigLayers, setFinalvMixConfigLayers] = useState("");
 const [vmixName, setVmixName] = useState("vMix Virtual Set - H2R Layouts");
 const vMixCopyRef = useRef();
 const vMixCopyRefLayers = useRef();

 // DJP
 const [finalMacroDJP, setFinalMacroDJP] = useState("");
 const [macroIndexDJP, setMacroIndexDJP] = useState(0);
 const [macroNameDJP, setMacroNameDJP] = useState("SuperSource Layout");
 const macroRefDJP = useRef();

 const location = useLocation();
 const history = useHistory();

 const params = new URLSearchParams(location.search);
 //  const mode = params.get("key");

 const updateBox = (id, update) => {
  updateBoxes(id, update);
  // return setBoxes(
  // 	boxes.map((w) => {
  // 		if (w.id !== id) return w;

  // 		return {
  // 			...w,
  // 			...update,
  // 		};
  // 	})
  // );
 };

 const updateBoxScale = (id) => {
  const stage = mainStage.current.getBoundingClientRect();
  const box = document.getElementById(`box${id}`);
  const boxBounds = box.getBoundingClientRect();

  const stageWidth = stage.width;
  const stageOffsetX = stage.x;
  const stageHeight = stage.height;
  const stageOffsetY = stage.y;

  const boxWidth = box.clientWidth;
  const boxOffsetX = boxBounds.x;
  const boxHeight = box.clientHeight;
  const boxOffsetY = boxBounds.y;

  let originX = boxOffsetX - stageOffsetX;
  let originY = boxOffsetY - stageOffsetY;

  updateBoxes(id, {
   calculatedPosition: {
    x: (originX + 1 + boxWidth / 2 - stageWidth / 2) / 24,
    y: (originY + 1 + boxHeight / 2 - stageHeight / 2) / -24,
   },
  });
 };

 const handleOnDrag = (e, data, id) => {
  const stage = mainStage.current.getBoundingClientRect();

  const stageWidth = stage.width;
  const stageHeight = stage.height;
  updateBox(id, {
   position: {
    x: data.x,
    y: data.y,
   },
   calculatedPosition: {
    x: (data.x - stageWidth / 2) / 24,
    y: (data.y - stageHeight / 2) / -24,
   },
  });
 };

 const parseCalculatedPositionX = (id, xPos, scale) => {
  const stage = mainStage.current.getBoundingClientRect();

  const stageWidth = stage.width;
  const caculatedPosition = stageWidth / 2 + xPos * 24;

  return updateBoxesX(id, {
   position: {
    x: caculatedPosition,
   },
   calculatedPosition: {
    x: xPos,
   },
  });
 };

 const parseCalculatedPositionY = (id, yPos, scale) => {
  const stage = mainStage.current.getBoundingClientRect();

  const stageHeight = stage.height;
  const caculatedPosition = stageHeight / 2 + yPos * -24;

  return updateBoxesY(id, {
   position: {
    y: caculatedPosition,
   },
   calculatedPosition: {
    y: yPos,
   },
  });
 };

 const handleMacroImport = (obj) => {
  const { name, index, description, Op } = obj.Macro;

  console.log(obj, name, index);

  setMacroName(name);
  setMacroIndex(index);
  setMacroDescription(description);

  return Op.map(async (op) => {
   let scale = Op.filter((o) => {
    if (o.boxIndex === op.boxIndex && o.id === "SuperSourceV2BoxSize")
     return true;
    else return false;
   });

   switch (op.id) {
    case "SuperSourceV2BoxEnable":
     return updateBoxes(parseInt(op.boxIndex), {
      show: JSON.parse(op.enable.toLowerCase()),
     });

    case "SuperSourceV2BoxInput":
     return updateBoxes(parseInt(op.boxIndex), {
      label: op.input,
     });

    case "SuperSourceV2BoxSize":
     return updateBoxes(parseInt(op.boxIndex), {
      scale: parseFloat(op.size),
     });

    // case "SuperSourceV2BoxMaskEnable":
    //  return updateBoxes(parseInt(op.boxIndex), {
    //   show: JSON.parse(op.enable.toLowerCase()),
    //  });

    case "SuperSourceV2BoxMaskLeft":
     return updateBoxCrop(parseInt(op.boxIndex), {
      left: op.left,
     });

    case "SuperSourceV2BoxMaskTop":
     return updateBoxCrop(parseInt(op.boxIndex), {
      top: op.top,
     });

    case "SuperSourceV2BoxMaskRight":
     return updateBoxCrop(parseInt(op.boxIndex), {
      right: op.right,
     });

    case "SuperSourceV2BoxMaskBottom":
     return updateBoxCrop(parseInt(op.boxIndex), {
      bottom: op.bottom,
     });

    case "SuperSourceV2BoxXPosition":
     return parseCalculatedPositionX(
      parseInt(op.boxIndex),
      parseFloat(op.xPosition),
      parseFloat(scale[0].size)
     );

    case "SuperSourceV2BoxYPosition":
     return parseCalculatedPositionY(
      parseInt(op.boxIndex),
      parseFloat(op.yPosition),
      parseFloat(scale[0].size)
     );

    default:
     break;
   }
  });
 };

 const fps = 16;

 const handleCalculateTweens = (id, reverse = false) => {
  const allStrings = [];

  // SCALE
  const startScale = parseFloat(boxes[id].scale);
  const endScale = 1;
  const diff = parseFloat(endScale) - parseFloat(startScale);
  let running = startScale;

  // Position X
  const startX = boxes[id].calculatedPosition.x;
  const endX = 0;
  const diffX = endX - startX;
  let runningX = startX;

  // Position Y
  const startY = boxes[id].calculatedPosition.y;
  const endY = 0;
  const diffY = parseFloat(endY) - parseFloat(startY);
  let runningY = startY;

  // CROP L
  const startL = parseFloat(boxes[id].cropped.left);
  const endL = 0;
  const diffL = parseFloat(endL) - parseFloat(startL);
  let runningL = startL;

  // CROP R
  const startR = parseFloat(boxes[id].cropped.right);
  const endR = 0;
  const diffR = parseFloat(endR) - parseFloat(startR);
  let runningR = startR;

  // CROP T
  const startT = parseFloat(boxes[id].cropped.top);
  const endT = 0;
  const diffT = parseFloat(endT) - parseFloat(startT);
  let runningT = startT;

  // CROP B
  const startB = parseFloat(boxes[id].cropped.bottom);
  const endB = 0;
  const diffB = parseFloat(endB) - parseFloat(startB);
  let runningB = startB;

  // LOOP
  for (let index = 0; index < fps / 2; index++) {
   if (reverse && index === 0) continue;
   const newTotalScale = running + diff / (fps / 2);
   running = newTotalScale;
   allStrings.push(
    `
	<Op id="SuperSourceV2BoxSize" superSource="${superSourceIndex}" boxIndex="${id}" size="${parseFloat(
     newTotalScale.toFixed(2)
    )}"/>
	${reverse ? `<Op id="MacroSleep" frames="1"/>` : ""}
	`
   );

   const newTotalL = runningL + diffL / (fps / 2);
   runningL = newTotalL;

   const newTotalR = runningR + diffR / (fps / 2);
   runningR = newTotalR;

   const newTotalT = runningT + diffT / (fps / 2);
   runningT = newTotalT;

   const newTotalB = runningB + diffB / (fps / 2);
   runningB = newTotalB;

   allStrings.push(
    `<Op id="SuperSourceV2BoxMaskLeft" superSource="${superSourceIndex}" boxIndex="${id}" left="${newTotalL.toFixed(
     2
    )}"/>
	<Op id="SuperSourceV2BoxMaskRight" superSource="${superSourceIndex}" boxIndex="${id}" right="${newTotalR.toFixed(
     2
    )}"/>
	<Op id="SuperSourceV2BoxMaskTop" superSource="${superSourceIndex}" boxIndex="${id}" top="${newTotalT.toFixed(
     2
    )}"/>
  <Op id="SuperSourceV2BoxMaskBottom" superSource="${superSourceIndex}" boxIndex="${id}" bottom="${newTotalB.toFixed(
     2
    )}"/>
	`
   );

   const newTotalX = runningX + diffX / (fps / 2);
   runningX = newTotalX;
   allStrings.push(
    `<Op id="SuperSourceV2BoxXPosition" superSource="${superSourceIndex}" boxIndex="${id}" xPosition="${newTotalX.toFixed(
     2
    )}"/>
	`
   );

   const newTotalY = runningY + diffY / (fps / 2);
   runningY = newTotalY;

   allStrings.push(`<Op id="SuperSourceV2BoxYPosition" superSource="${superSourceIndex}" boxIndex="${id}" yPosition="${newTotalY.toFixed(
    2
   )}"/>
   ${!reverse ? `<Op id="MacroSleep" frames="1"/>` : ""}

   `);
  }

  if (reverse) return allStrings.reverse().join("");

  return allStrings.join("");
 };

 useEffect(() => {
  // ATEM
  const macro = boxes.map((box) => {
   //  let superSourceIndex = box.id <= 3 ? 0 : 1;
   if (box.id > 3) {
    return "";
   } else if (box.show === false) {
    return `<Op id="SuperSourceV2BoxEnable" superSource="${superSourceIndex}" boxIndex="${box.id}" enable="False" />\n`;
   } else {
    return `<Op id="SuperSourceV2BoxEnable" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" enable="True" />${
     setSourcesBoolean
      ? `\n<Op id="SuperSourceV2BoxInput" superSource="${superSourceIndex}" boxIndex="${box.id}" input="${box.label}"/>`
      : ""
    }
            			<Op id="SuperSourceV2BoxSize" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" size="${box.scale}"/>
            			<Op id="SuperSourceV2BoxXPosition" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" xPosition="${box.calculatedPosition.x}"/>
           				<Op id="SuperSourceV2BoxYPosition" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" yPosition="${box.calculatedPosition.y}"/>
                  <Op id="SuperSourceV2BoxMaskEnable" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" enable="True"/>
                  <Op id="SuperSourceV2BoxMaskLeft" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" left="${box.cropped.left}"/>
                  <Op id="SuperSourceV2BoxMaskTop" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" top="${box.cropped.top}"/>
                  <Op id="SuperSourceV2BoxMaskRight" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" right="${box.cropped.right}"/>
                  <Op id="SuperSourceV2BoxMaskBottom" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" bottom="${box.cropped.bottom}"/>
    					`;
   }
  });
  //console.log(macro);
  setFinalMacro(`<Macro index="${macroIndex}" name="${macroName}" description="${macroDescription}">
		${macro.join("")}</Macro>`);

  // DJP
  let macroDJP = boxes.map((box) => {
   //  let superSourceIndex = box.id <= 3 ? 0 : 1;
   if (box.id > 3) {
    return "";
   } else if (box.show === false) {
    return ``;
   } else {
    return `SetBox(${box.id + 1}, ${box.scale}, ${box.calculatedPosition.x}, ${
     box.calculatedPosition.y
    }, ${box.cropped.left}, ${box.cropped.right}, ${box.cropped.top}, ${
     box.cropped.bottom
    } );
    `;
   }
  });
  //console.log(macro);
  setFinalMacroDJP(`elseif layout == ${macroIndexDJP} then\nlayoutname = "${macroNameDJP}";
     ${macroDJP.join("")}`);

  // ANIMATED ATEM SUPERSOURCE
  const macroATEMAnimated = boxes
   //   .sort((a, b) => {
   //    if (a.show === false) {
   //     return -1;
   //    } else return 1;
   //   })
   .map((b, i) => {
    if (b.id > 3) return null;

    let allMacros = [];

    allMacros.push(`<!--Split -> Box ${b.id + 1} full-->
	<Macro index="${parseInt(macroIndexATEMAnimated) + b.id}" name="Split -> Box ${
     b.id + 1
    } full" description="${macroDescription}">
	${boxes
  .map((box) => {
   if (box.id > 3) return null;
   if (b.id !== box.id)
    return `<Op id="SuperSourceV2BoxEnable" superSource="${superSourceIndex}" boxIndex="${box.id}" enable="False" />\n`;
  })
  .join("")}
	<Op id="SuperSourceV2BoxEnable" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" enable="True" />
    ${handleCalculateTweens(b.id, false)}	
				<Op id="SuperSourceV2BoxMaskEnable" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" enable="True"/>
				<Op id="SuperSourceV2BoxMaskTop" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" top="0"/>
				<Op id="SuperSourceV2BoxMaskBottom" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" bottom="0"/>
	</Macro>

	`);

    allMacros.push(`<!--Box ${b.id + 1} full -> Split-->
	<Macro index="${parseInt(macroIndexATEMAnimated) + b.id + 4}" name="Box ${
     b.id + 1
    } full -> Split" description="${macroDescription}">
	   <Op id="SuperSourceV2BoxEnable" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" enable="True" />
	${boxes
  .map((box) => {
   if (box.id > 3) return null;
   if (b.id !== box.id)
    return `  
		  <Op id="SuperSourceV2BoxEnable" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" enable="${box.show === true ? "True" : "False"}" />
	
		  <Op id="SuperSourceV2BoxSize" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" size="${box.scale}"/>
		  <Op id="SuperSourceV2BoxXPosition" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" xPosition="${box.calculatedPosition.x}"/>
			 <Op id="SuperSourceV2BoxYPosition" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" yPosition="${box.calculatedPosition.y}"/>
	<Op id="SuperSourceV2BoxMaskEnable" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" enable="True"/>
	<Op id="SuperSourceV2BoxMaskLeft" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" left="${box.cropped.left}"/>
	<Op id="SuperSourceV2BoxMaskTop" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" top="${box.cropped.top}"/>
	<Op id="SuperSourceV2BoxMaskRight" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" right="${box.cropped.right}"/>
	<Op id="SuperSourceV2BoxMaskBottom" superSource="${superSourceIndex}" boxIndex="${
     box.id
    }" bottom="${box.cropped.bottom}"/>  `;
  })
  .join("")}
	 
				 ${handleCalculateTweens(b.id, true)}	
				 
				   <Op id="SuperSourceV2BoxSize" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" size="${b.scale}"/>
				   <Op id="SuperSourceV2BoxXPosition" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" xPosition="${b.calculatedPosition.x}"/>
					  <Op id="SuperSourceV2BoxYPosition" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" yPosition="${b.calculatedPosition.y}"/>
			 <Op id="SuperSourceV2BoxMaskEnable" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" enable="True"/>
			 <Op id="SuperSourceV2BoxMaskLeft" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" left="${b.cropped.left}"/>
			 <Op id="SuperSourceV2BoxMaskTop" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" top="${b.cropped.top}"/>
			 <Op id="SuperSourceV2BoxMaskRight" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" right="${b.cropped.right}"/>
			 <Op id="SuperSourceV2BoxMaskBottom" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" bottom="${b.cropped.bottom}"/>
				   <Op id="SuperSourceV2BoxMaskEnable" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" enable="True"/>
				   <Op id="SuperSourceV2BoxMaskLeft" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" left="${b.cropped.left}"/>
				   <Op id="SuperSourceV2BoxMaskTop" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" top="${b.cropped.top}"/>
				   <Op id="SuperSourceV2BoxMaskRight" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" right="${b.cropped.right}"/>
				   <Op id="SuperSourceV2BoxMaskBottom" superSource="${superSourceIndex}" boxIndex="${
     b.id
    }" bottom="${b.cropped.bottom}"/>
	 
	   </Macro>
	`);

    return allMacros.join("");
   });
  //console.log(macro);
  setFinalMacroATEMAnimated(`${macroATEMAnimated.join("")}`);
 }, [
  boxes,
  macroName,
  macroIndex,
  macroNameATEMAnimated,
  macroIndexATEMAnimated,
  macroNameDJP,
  macroIndexDJP,
  superSourceIndex,
  setSourcesBoolean,
 ]);

 useEffect(() => {
  const macro = boxes.map((box) => {
   if (box.show === false) {
    return ``;
   } else {
    return `<input name="Box${box.id + 1}" x="${
     box.calculatedPosition.x / 8 / 2
    }" y="${box.calculatedPosition.y / 9}" zoomX="${box.scale}" zoomY="${
     box.scale
    }" rotateX="0" rotateY="0" rotateZ="0" cropping="${
     box.cropped.left / 16 / 2
    },${box.cropped.top / 8 / 2},${1 - box.cropped.right / 16 / 2},${
     1 - box.cropped.bottom / 8 / 2
    }" dynamic="True">${box.id + 1}.png</input>
				<zoom name="ZoomBox${box.id + 1}" x="${
     -(box.calculatedPosition.x / 8 / 2) / box.scale
    }" y="${-(box.calculatedPosition.y / 9) / box.scale}" zoomX="${
     1 / box.scale
    }" zoomY="${1 / box.scale}" rotateX="0" rotateY="0" rotateZ="0" />
    					`;
   }
  });
  //console.log(macro);
  setFinalvMixConfig(`<virtualSet>
		<input name="Background" x="0" y="0" zoom="1" dynamic="True">back.png</input>
		<zoom name="Full" x="0" y="0" zoomX="1" zoomY="1" rotateX="0" rotateY="0" rotateZ="0" />
		${macro.join("")}</virtualSet>`);

  // LAYERS

  const macroLayers = boxes.map((box) => {
   return `<MatrixPosition>
        <Hidden>${!box.show}</Hidden>
        <Clipping>
          <float>${box.cropped.left / 16 / 2}</float>
          <float>${box.cropped.top / 8 / 2}</float>
          <float>${1 - box.cropped.right / 16 / 2}</float>
          <float>${1 - box.cropped.bottom / 8 / 2}</float>
        </Clipping>
        <Border>
          <Enabled>false</Enabled>
          <Thickness>0</Thickness>
          <Radius>0</Radius>
          <ColourHTML>White</ColourHTML>
        </Border>
        <Mirror>false</Mirror>
        <ZoomX>${box.scale}</ZoomX>
        <ZoomY>${box.scale}</ZoomY>
        <PostZoomX>1</PostZoomX>
        <PostZoomY>1</PostZoomY>
        <RotateOrigin>
          <X>0</X>
          <Y>0</Y>
          <Z>0</Z>
        </RotateOrigin>
        <Rotate>
          <X>0</X>
          <Y>0</Y>
          <Z>0</Z>
        </Rotate>
        <PanX>${box.calculatedPosition.x / 8 / 2}</PanX>
        <PanY>${box.calculatedPosition.y / 9}</PanY>
      </MatrixPosition>
      `;
  });
  //console.log(macro);
  setFinalvMixConfigLayers(`<?xml version="1.0" encoding="utf-16"?>
    <ArrayOfMatrixPosition xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
		${macroLayers.join("")}<MatrixPosition>
    <Hidden>true</Hidden>
    <Border>
      <Enabled>false</Enabled>
      <Thickness>0</Thickness>
      <Radius>0</Radius>
      <ColourHTML>White</ColourHTML>
    </Border>
    <Mirror>false</Mirror>
    <ZoomX>1</ZoomX>
    <ZoomY>1</ZoomY>
    <PostZoomX>1</PostZoomX>
    <PostZoomY>1</PostZoomY>
    <RotateOrigin>
      <X>0</X>
      <Y>0</Y>
      <Z>0</Z>
    </RotateOrigin>
    <Rotate>
      <X>0</X>
      <Y>0</Y>
      <Z>0</Z>
    </Rotate>
    <PanX>0</PanX>
    <PanY>0</PanY>
  </MatrixPosition>
  </ArrayOfMatrixPosition>`);
 }, [boxes, macroName, macroIndex]);

 // SET MODE FROM URL PARAMS
 useEffect(() => {
  const paramsMode = params.get("mode");

  if (paramsMode === "atem") {
   setMode("atem");
  } else if (paramsMode === "vmix") {
   setMode("vmix");
  }
 }, []);

 return (
  <div className="bg-white">
   <Header />
   <Modal
    open={xmlModalOpen}
    onClose={setXmlModalOpen}
    handleMacroImport={(xml) => {
     handleMacroImport(xml);

     return setXmlModalOpen(false);
    }}
   />
   <main className="mx-auto">
    <section className="bg-gray-700 pt-2 pb-2">
     <div className="max-w-3xl mx-auto flex justify-between">
      <div>
       <span className="text-gray-400 text-sm uppercase mr-2">
        Drag Precision
       </span>
       <select
        className="px-1 py-1 text-xs font-semibold cursor-pointer uppercase border border-gray-800 bg-gray-700 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
        value={snap}
        onChange={(e) => {
         console.log(snap, e.target.selected);
         setSnap(e.target.value);
        }}
       >
        {snapGrids.map((s, i) => {
         return (
          <option key={i} value={i}>
           {s.label}
          </option>
         );
        })}
       </select>
      </div>

      <div>
       <span className="text-gray-400 text-sm uppercase mr-2">Guides</span>
       <select
        className="px-1 py-1 text-xs font-semibold cursor-pointer uppercase border border-gray-800 bg-gray-700 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
        value={guide}
        onChange={(e) => {
         setGuide(e.target.value);
        }}
       >
        {guides.map((s, i) => {
         return (
          <option key={i} value={i}>
           {s.label}
          </option>
         );
        })}
       </select>
      </div>
     </div>
    </section>
    {/* MAIN STAGE */}
    {/* x {lockX ? "l" : ""}, y {lockY ? "l" : ""} */}
    <section
     onKeyDown={(e) => {
      console.log(e.keyCode);
     }}
     className="flex flex-col items-center justify-center bg-gray-700"
    >
     <div
      ref={mainStage}
      id="mainstage"
      onMouseEnter={() => {
       setMainStageHovered(true);
      }}
      onMouseLeave={() => {
       setMainStageHovered(false);
      }}
      className={`m-0 bg-gray-900 relative z-10`}
      style={{
       width: `${videoRatios[5].width}px`,
       height: `${videoRatios[5].height}px`,
      }}
     >
      {/* BOXES */}
      {boxes
       .sort((a, b) => {
        if (mode === "atem") {
         if (a.id < b.id) {
          return 1;
         }
         if (a.id > b.id) {
          return -1;
         }
        } else {
         if (a.id < b.id) {
          return -1;
         }
         if (a.id > b.id) {
          return 1;
         }
        }

        return 0;
       })
       .map((box) => {
        //if (!box.show) return null;
        return (
         <Draggable
          key={box.id}
          name={`box${box.id}`}
          // bounds="parent"
          // axis="y"
          handle=".handle"
          position={box.position}
          positionOffset={{ x: "-50%", y: "-50%" }}
          onDrag={(e, data) => {
           handleOnDrag(e, data, box.id);
          }}
          grid={snapGrids[parseInt(snap)].grid}
          scale={1}
          defaultClassName="absolute cursor-pointer"
          defaultClassNameDragging="border border-3 z-50"
         >
          <div
           id={`box${box.id}`}
           ref={(ref) => {
            ref = boxesRef[box.id];
           }}
           onClick={() => {
            // return setBoxes(
            // 	boxes.map((w) => {
            // 		if (w.id !== box.id)
            // 			return {
            // 				...w,
            // 				editing: false,
            // 			};
            // 		return {
            // 			...w,
            // 			editing: true,
            // 		};
            // 	})
            // );
           }}
           className={`bg-${
            box.colour
           }-500 flex justify-center items-center text-white border border-${
            box.colour
           }-600 select-none
														${!box.show && "opacity-0 pointer-events-none"}
											`}
           style={{
            // CALCULATING clip path, 100
            clipPath: `inset(${(box.cropped.top * 100) / 32}% ${
             (box.cropped.right * 100) / 32
            }% ${(box.cropped.bottom * 100) / 32}% ${
             (box.cropped.left * 100) / 32
            }%)`,
            width: `${box.scale * 100}%`,
            height: `${box.scale * 100}%`,
            // position: "absolute",
            // top: "25%",
            // left: "25%",
           }}
          >
           <div className="handle flex h-full w-full justify-center relative removeFromPngExport">
            {showGuides && (
             <>
              <span
               className={`bg-${box.colour}-800 w-px h-100 opacity-20`}
              ></span>
              <span
               className={`bg-${box.colour}-800 w-100 h-px absolute top-0 bottom-0 left-0 right-0 my-auto opacity-20`}
              ></span>
             </>
            )}
           </div>
           {/* BOX LABEL */}
           <span
            className={`px-2 py-1 absolute bg-${
             box.colour
            }-600 rounded flex flex-col justify-center items-center pointer-events-none transform 
															${box.scale < 0.2 ? "scale-50" : "scale-100"}
															`}
           >
            {mode === "atem" && (
             <span className={`text-${box.colour}-700 text-xs font-semibold`}>
              BOX {box.id + 1}
             </span>
            )}
            {mode === "atem" ? box.label : `Box ${box.id + 1}`}
           </span>
          </div>
         </Draggable>
        );
       })}

      <div className="flex h-full w-100 justify-center relative pointer-events-none opacity-100">
       {images.length > 0 && (
        <div className="w-100 h-100 absolute top-0 bottom-0 left-0 right-0 mx-auto">
         <div
          className={`flex h-full w-100 justify-center relative pointer-events-none bg-cover bg-center transition duration-300 ease-in-out
          ${mainStageHovered ? "opacity-20" : "opacity-100"}
         
          `}
          style={{
           backgroundImage: `url(${images[0].url})`,
           zIndex: `${imageIsForeground ? "1" : "-1"}`,
          }}
         ></div>
        </div>
       )}

       {showGuides && (
        <div
         className="opacity-30 h-100 removeFromPngExport"
         style={{
          backgroundImage: `url(${guides[guide].gridImage})`,
          backgroundSize: "cover",
          width: "100%",
          backgroundPosition: "center",
         }}
        >
         {/* <span className="bg-gray-100 w-px h-100 absolute top-0 bottom-0 left-0 right-0 mx-auto"></span>
         <span className="bg-gray-100 w-100 h-px absolute top-0 bottom-0 left-0 right-0 my-auto"></span> */}
        </div>
       )}
      </div>
     </div>
    </section>
    <section className="bg-gray-700 pt-1 pb-3">
     <div className="max-w-3xl mx-auto flex justify-between">
      {/* UPLOAD IMAGE */}
      <div>
       <span className="text-gray-400 text-sm uppercase mr-2">Image</span>
       {images.length === 0 ? (
        <>
         <input
          type="file"
          id="upload_image"
          hidden
          onChange={(e) => {
           addImage(URL.createObjectURL(e.target.files[0]));
          }}
         />
         <label
          htmlFor="upload_image"
          className="px-1 py-1 text-xs font-semibold cursor-pointer uppercase border border-gray-800 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
         >
          Upload
         </label>
        </>
       ) : (
        <>
         <button
          onClick={(e) => {
           toggleImageIsForeground();
          }}
          className="px-1 py-1 text-xs font-semibold uppercase border border-gray-800 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
         >
          {imageIsForeground ? "Move to back" : "Bring to front"}
         </button>

         <button
          onClick={(e) => {
           removeImage();
          }}
          className="ml-2 px-1 py-1 text-xs font-semibold uppercase border border-gray-800 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
         >
          Remove
         </button>
        </>
       )}
      </div>
      {mode === "atem" && (
       <button
        className="ml-2 px-1 py-1 text-xs font-semibold uppercase border border-gray-800 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
        onClick={() => setXmlModalOpen(true)}
       >
        Import macro
       </button>
      )}
      <button
       className="ml-2 px-1 py-1 text-xs font-semibold uppercase border border-gray-800 text-gray-400 hover:bg-gray-800 transition duration-300 rounded focus:outline-none focus:ring"
       onClick={() => {
        // exportComponentAsPNG(mainStage, {
        //  fileName: `${macroName} - H2R Layouts`,
        //  html2CanvasOptions: {
        //   // foreignObjectRendering: true,
        //   // scale: pngQuality,
        //   backgroundColor: "transparent",
        //   ignoreElements: function (el) {
        //    return el.classList.contains("removeFromPngExport");
        //   },
        //  },
        // })
        domtoimage
         .toBlob(mainStage.current, {
          width: 768,
          height: 432,
          filter: function (el) {
           if (el.classList?.contains("removeFromPngExport")) {
            return false;
           }
           return true;
          },
         })
         .then(function (blob) {
          window.saveAs(blob, `${macroName} - H2R Layouts`);
         });
       }}
      >
       Export As PNG
      </button>
     </div>
    </section>
    {/* <textarea
     defaultValue=""
     tabIndex="2"
     autoFocus
     onChange={(e) => {
      const xmlData = e.target.value;

      var options = {
       attributeNamePrefix: "",
       ignoreAttributes: false,
      };

      if (parser.validate(xmlData) === true) {
       //optional (it'll return an object in case it's not valid)
       var jsonObj = parser.parse(xmlData, options);
       handleMacroImport(jsonObj);
      }
     }}
    /> */}
    {/* BOX ALIGNMENT  */}
    {/* <BoxAlignment updateBoxScale={updateBoxScale} /> */}
    {/* CONTROLS */}
    <section
     className={`max-w-3xl  mt-2 mx-auto
                        ${mode === "vmix" && "lg:max-w-5xl xl:max-w-6xl"}
        `}
    >
     <div className="flex flex-col items-center w-100 space-y-2">
      {/* <div className="text-gray-500 uppercase text-xs font-semibold">
							Mode
						</div> */}
      <div>
       <button
        onClick={() => {
         setMode("atem");
         return history.push("?mode=atem");
        }}
        className={`px-2 py-1 rounded-l-lg border focus:outline-none focus:ring ring-gray-400
								${
         mode === "atem"
          ? "bg-gray-500 text-gray-100  border-gray-500"
          : "bg-transparent text-gray-500  border-gray-200"
        }
								`}
       >
        ATEM SuperSource
       </button>
       <button
        onClick={() => {
         setMode("vmix");
         return history.push("?mode=vmix");
        }}
        className={` px-2 py-1 rounded-r-lg border focus:outline-none focus:ring ring-gray-400
								${
         mode === "vmix"
          ? "bg-gray-500 text-gray-100  border-gray-500"
          : "bg-transparent text-gray-500  border-gray-200"
        }
								`}
       >
        vMix Virtual Set
       </button>
      </div>
     </div>
     <div className="mt-2 px-2 py-1 flex overflow-auto justify-between items-baseline mx-auto space-x-4">
      {boxes
       .sort((a, b) => {
        if (a.id < b.id) {
         return -1;
        }
        if (a.id > b.id) {
         return 1;
        }
        return 0;
       })
       .map((box) => {
        if (mode === "atem" && box.id > 3) return null;
        return (
         <BoxConfiguration
          key={box.id}
          box={box}
          boxes={boxes}
          // setBoxes={setBoxes}
          updateBox={updateBox}
          handleOnDrag={handleOnDrag}
          updateBoxScale={updateBoxScale}
         />
        );
       })}
     </div>
    </section>
    {mode === "atem" && (
     <>
      <section className="mt-10 max-w-3xl mx-auto ">
       <h2>ATEM SuperSource macro</h2>
       <div className="flex justify-between items-center">
        <div className="flex space-x-4">
         <div>
          <h3>Macro name</h3>
          <input
           className="px-1 py-1 text-xs w-34 border border-gray-200 rounded focus:outline-none focus:ring"
           type="text"
           value={macroName}
           onChange={(e) => {
            setMacroName(e.target.value);
           }}
          />
         </div>
         <div>
          <h3>Macro Index</h3>
          <input
           className="px-1 py-1 text-xs w-20 border border-gray-200 rounded focus:outline-none focus:ring"
           type="number"
           value={macroIndex}
           min="0"
           onChange={(e) => {
            setMacroIndex(e.target.value);
           }}
          />
         </div>
         <div>
          <h3>SuperSource</h3>
          <input
           className="px-1 py-1 text-xs w-20 border border-gray-200 rounded focus:outline-none focus:ring"
           type="number"
           value={superSourceIndex + 1}
           min="1"
           max="2"
           onChange={(e) => {
            setSuperSourceIndex(parseInt(e.target.value) - 1);
           }}
          />
         </div>
         <div>
          <h3>Set input sources</h3>
          <div className="w-100 flex items-center space-x-2">
           <input
            className="px-1 py-1 text-xs  cursor-pointer border border-gray-200 rounded focus:outline-none focus:ring"
            type="checkbox"
            id="setInputSources"
            checked={setSourcesBoolean}
            onChange={(e) => {
             setSources(!setSourcesBoolean);
            }}
           />
           <label className="w-full  cursor-pointer" htmlFor="setInputSources">
            {setSourcesBoolean ? "Yes" : "No"}
           </label>
          </div>
         </div>
        </div>

        <div className="flex space-x-3">
         <Link
          to="/tutorial"
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
           </svg>
           <span>Need help?</span>
          </span>
         </Link>
         <button
          onClick={(e) => {
           const textToCopy = macroRef.current;

           navigator.clipboard.writeText(textToCopy.innerText);

           e.target.innerText = "Copied to clipboard!";

           analytics.logEvent("macro_copied");

           setTimeout(() => {
            return (e.target.innerText = "Copy macro");
           }, 3000);
          }}
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          Copy macro
         </button>
        </div>
       </div>
       <pre>
        <code
         ref={macroRef}
         className="block max-h-20 overflow-auto mt-2 px-2 py-2 whitespace-pre-line text-xs border border-gray-200 bg-gray-100 rounded"
        >
         {finalMacro}
        </code>
       </pre>
      </section>

      <section className="mt-10 max-w-3xl mx-auto ">
       <h2>ATEM SuperSource animated</h2>
       <div className="flex justify-between items-center">
        <div className="flex space-x-4">
         {/* <div>
          <h3>Macro name</h3>
          <input
           className="px-1 py-1 text-xs w-42 border border-gray-200 rounded focus:outline-none focus:ring"
           type="text"
           value={macroNameATEMAnimated}
           onChange={(e) => {
            setMacroNameATEMAnimated(e.target.value);
           }}
          />
         </div> */}
         {/* <div>
          <h3>Starting Macro Index</h3>
          <input
           className="px-1 py-1 text-xs w-20 border border-gray-200 rounded focus:outline-none focus:ring"
           type="number"
           value={macroIndexATEMAnimated}
           min="0"
           max={92}
           onChange={(e) => {
            setMacroIndexATEMAnimated(e.target.value);
           }}
          />
         </div> */}
        </div>

        <div className="flex space-x-3">
         <Link
          to="/tutorial"
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
           </svg>
           <span>Need help?</span>
          </span>
         </Link>

         <button
          onClick={async (e) => {
           analytics.logEvent("companion_page_download");

           let defaultCompanionPage = JSON.parse(JSON.stringify(companionPage));

           boxes.map((box) => {
            if (box.id > 3) return null;

            if (box.show === false) {
             defaultCompanionPage.actions[box.id + 2] = [];
             defaultCompanionPage.actions[box.id + 10] = [];

             defaultCompanionPage.config[box.id + 2] = {};
             defaultCompanionPage.config[box.id + 10] = {};

             defaultCompanionPage.feedbacks[box.id + 2] = [];
             defaultCompanionPage.feedbacks[box.id + 10] = [];
            } else if (box.show === true) {
             // ENABLE BUTTONS
             defaultCompanionPage.actions[box.id + 2] =
              companionPage.actions[box.id + 2];
             defaultCompanionPage.actions[box.id + 10] =
              companionPage.actions[box.id + 10];

             defaultCompanionPage.config[box.id + 2] =
              companionPage.config[box.id + 2];
             defaultCompanionPage.config[box.id + 10] =
              companionPage.config[box.id + 10];

             defaultCompanionPage.feedbacks[box.id + 2] =
              companionPage.feedbacks[box.id + 2];
             defaultCompanionPage.feedbacks[box.id + 10] =
              companionPage.feedbacks[box.id + 10];

             // SET COMPANION INPUT
             let sourceIndex =
              sources.findIndex((x) => x.name === box.label) + 1;
             defaultCompanionPage.actions[box.id + 10][1].options.source =
              sourceIndex;
             defaultCompanionPage.actions[box.id + 10][2].options.input =
              sourceIndex;
             defaultCompanionPage.feedbacks[box.id + 10][0].options.input =
              sourceIndex;
            }
           });

           console.log(defaultCompanionPage);

           const fileName = "H2RLayoutsCompanionPage";
           const json = JSON.stringify(defaultCompanionPage);
           const blob = new Blob([json], { type: "application/json" });
           const href = await URL.createObjectURL(blob);
           const link = document.createElement("a");
           link.href = href;
           link.download = fileName + ".companionconf";
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
          }}
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
           </svg>
           <span>Download Companion page</span>
          </span>
         </button>

         <button
          onClick={(e) => {
           const textToCopy = macroRefATEMAnimated.current;

           navigator.clipboard.writeText(textToCopy.innerText);

           e.target.innerText = "Copied to clipboard!";

           analytics.logEvent("macro_copied");

           setTimeout(() => {
            return (e.target.innerText = "Copy macro");
           }, 3000);
          }}
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          Copy macros
         </button>
        </div>
       </div>
       <pre>
        <code
         ref={macroRefATEMAnimated}
         className="block max-h-20 overflow-auto mt-2 px-2 py-2 whitespace-pre-line text-xs border border-gray-200 bg-gray-100 rounded"
        >
         {finalMacroATEMAnimated}
        </code>
       </pre>
      </section>

      <section className="mt-10 max-w-3xl mx-auto ">
       <h2>DJP’s ATEM SuperSource Macro for JustMacros</h2>
       <div className="flex justify-between items-center">
        <div className="flex space-x-4">
         <div>
          <h3>Layout name</h3>
          <input
           className="px-1 py-1 text-xs w-42 border border-gray-200 rounded focus:outline-none focus:ring"
           type="text"
           value={macroNameDJP}
           onChange={(e) => {
            setMacroNameDJP(e.target.value);
           }}
          />
         </div>
         <div>
          <h3>Layout Index</h3>
          <input
           className="px-1 py-1 text-xs w-20 border border-gray-200 rounded focus:outline-none focus:ring"
           type="number"
           value={macroIndexDJP}
           min="0"
           onChange={(e) => {
            setMacroIndexDJP(e.target.value);
           }}
          />
         </div>
        </div>

        <div className="flex space-x-2">
         <a
          href="https://djp.li/ssanimation"
          target="_blank"
          rel="noopener noreferrer"
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
           </svg>
           <span>What's this?</span>
          </span>
         </a>
         <button
          onClick={(e) => {
           const textToCopy = macroRefDJP.current;

           navigator.clipboard.writeText(textToCopy.innerText);

           e.target.innerText = "Copied to clipboard!";

           analytics.logEvent("macro_copied_djp");

           setTimeout(() => {
            return (e.target.innerText = "Copy XML");
           }, 3000);
          }}
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          Copy XML
         </button>
        </div>
       </div>
       <pre>
        <code
         ref={macroRefDJP}
         className="block max-h-20 overflow-auto mt-2 px-2 py-2 whitespace-pre-line text-xs border border-gray-200 bg-gray-100 rounded"
        >
         {finalMacroDJP}
        </code>
       </pre>
      </section>
     </>
    )}
    {mode === "vmix" && (
     <section className="mt-10 max-w-3xl mx-auto ">
      <div>
       <h2>vMix Virtual Set</h2>
       <div className="flex justify-between items-end">
        <div className="flex space-x-4">
         <div>
          <h3>Name</h3>
          <input
           className="px-1 py-1 text-xs w-56 border border-gray-200 rounded focus:outline-none focus:ring"
           type="text"
           value={vmixName}
           onChange={(e) => {
            setVmixName(e.target.value);
           }}
          />
         </div>
        </div>
        <div className="flex justify-between space-x-2">
         <Link
          to="/tutorial"
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
           </svg>
           <span>Need help?</span>
          </span>
         </Link>
         <button
          onClick={(e) => {
           analytics.logEvent("vmix_downloaded");

           let zip = new JSZip();

           zip.file("config.xml", finalvMixConfig);

           let img1 = imageToBase64(vmixImg1).then((res) => res);
           let img2 = imageToBase64(vmixImg2).then((res) => res);
           let img3 = imageToBase64(vmixImg3).then((res) => res);
           let img4 = imageToBase64(vmixImg4).then((res) => res);
           let img5 = imageToBase64(vmixImg5).then((res) => res);
           let img6 = imageToBase64(vmixImg6).then((res) => res);
           let img7 = imageToBase64(vmixImg7).then((res) => res);
           let img8 = imageToBase64(vmixImg8).then((res) => res);
           let img9 = imageToBase64(vmixImg9).then((res) => res);
           let imgBack = imageToBase64(vmixImgBack).then((res) => res);

           zip.file("1.png", img1, { base64: true });
           zip.file("2.png", img2, { base64: true });
           zip.file("3.png", img3, { base64: true });
           zip.file("4.png", img4, { base64: true });
           zip.file("5.png", img5, { base64: true });
           zip.file("6.png", img6, { base64: true });
           zip.file("7.png", img7, { base64: true });
           zip.file("8.png", img8, { base64: true });
           zip.file("9.png", img9, { base64: true });
           zip.file("back.png", imgBack, { base64: true });

           zip.generateAsync({ type: "blob" }).then(function (content) {
            // see FileSaver.js
            saveAs(content, `${vmixName}.zip`);
           });
          }}
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
           </svg>
           <span>Download Virtual Set</span>
          </span>
         </button>
        </div>
       </div>
       <pre>
        <code
         ref={vMixCopyRef}
         className="block max-h-20 overflow-auto mt-2 px-2 py-2 whitespace-pre-line text-xs border border-gray-200 bg-gray-100 rounded"
        >
         {finalvMixConfig}
        </code>
       </pre>
      </div>
      <div className="mt-6">
       <h2>vMix Layers/Multiview</h2>
       <div className="flex justify-between items-end">
        <div className="flex space-x-4">
         {/* <div>
										<h3>Name</h3>
										<input
											className="px-1 py-1 text-xs w-56 border border-gray-200 rounded focus:outline-none focus:ring"
											type="text"
											value={vmixName}
											onChange={(e) => {
												setVmixName(e.target.value);
											}}
										/>
									</div> */}
        </div>
        <div className="flex justify-between space-x-2">
         <Link
          to="/tutorial"
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
           </svg>
           <span>Need help?</span>
          </span>
         </Link>
         <button
          onClick={(e) => {
           analytics.logEvent("vmix_downloaded_layers");

           let zip = new JSZip();

           zip.file("layers.vMixLayout", finalvMixConfigLayers);

           zip.generateAsync({ type: "blob" }).then(function (content) {
            // see FileSaver.js
            saveAs(content, `vMixLayout.zip`);
           });
          }}
          className="px-2 py-1 text-sm border border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring"
         >
          <span className="flex justify-center items-center space-x-2 text-gray-600">
           <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth={2}
             d="M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
           </svg>
           <span>Download layout</span>
          </span>
         </button>
        </div>
       </div>
       <pre>
        <code
         ref={vMixCopyRefLayers}
         className="block  max-h-20 overflow-auto mt-2 px-2 py-2 whitespace-pre-line text-xs border border-gray-200 bg-gray-100 rounded"
        >
         {finalvMixConfigLayers}
        </code>
       </pre>
      </div>
     </section>
    )}
    <PurchasePack mode={mode} />
    {mode === "atem" && <PurchasePack mode="atem-animated" />}
    <section className="mt-10 max-w-3xl mx-auto ">
     <h2>Known issues</h2>
     <ul className=" list-disc text-sm">
      <li>Keep your browser window wide, otherwise things get weird.</li>
     </ul>
    </section>
    {/* <section className="mt-10 max-w-3xl mx-auto ">
     <h2>Planned features</h2>
     <ul className=" list-disc text-sm">
      <li>Upload previously created macro for editing.</li>
      <li>Add DVE boxes.</li>
      <li>Support for bigger ATEMs.</li>
     </ul>
    </section> */}
    <Footer />
    {/* <footer className="mt-10 bg-gray-800">
     <div className="pt-4 pb-6 max-w-3xl text-sm mx-auto text-gray-400">
      Made with ❤️ and ☕️ by{" "}
      <a
       className="underline"
       href="https://h2r.li/home"
       target="_blank"
       rel="noreferrer"
      >
       Here to Record
      </a>
      .
     </div>
    </footer> */}
   </main>
  </div>
 );
}

const BoxAlignment = ({ updateBoxScale }) => {
 const boxes = useSelector((state) => state.boxes);

 const handleAlignL = () => {
  let position = 10000;

  boxes.map((box) => {
   let p = box.position.x * parseFloat(box.scale);
   if (box.show) {
    if (box.position.x <= position) {
     position = box.position.x;
    }
   }
  });

  boxes.map((box) => {
   if (box.show && box.position.x !== position) {
    console.log(
     position,
     box.position.x,
     position * parseFloat(box.scale),
     position - position * parseFloat(box.scale),
     position / parseFloat(box.scale)
    );
    updateBoxes(box.id, {
     position: {
      ...box.position,
      x: parseFloat(position * parseFloat(box.scale) * 2),
     },
    });
    setTimeout(() => {
     updateBoxScale(box.id);
    }, 100);
   }
  });
 };

 const handleAlignBoxes = (direction = "v") => {
  let center = 0;
  let count = 0;

  boxes.map((box) => {
   if (box.show) {
    count++;
    if (direction === "v") {
     center = center + box.position.x;
    } else if (direction === "h") {
     center = center + box.position.y;
    }
   }
  });

  boxes.map((box) => {
   if (box.show) {
    updateBoxes(box.id, {
     position: {
      ...box.position,
      [direction === "v" ? "x" : "y"]: parseFloat(center / count),
     },
    });
    setTimeout(() => {
     updateBoxScale(box.id);
    }, 100);
   }
  });
 };

 return (
  <div className="flex space-x-1">
   <button
    onClick={() => {
     handleAlignBoxes("v");
    }}
    className="px-1 py-1 bg-gray-400"
   >
    CENTER VERTICAL align all
   </button>
   <button
    onClick={() => {
     handleAlignBoxes("h");
    }}
    className="px-1 py-1 bg-gray-400"
   >
    CENTER HORZ align all
   </button>
   {/* <button
    onClick={() => {
     handleAlignL("l");
    }}
    className="px-1 py-1 bg-gray-400"
   >
    LEFT align all
   </button> */}
  </div>
 );
};

const BoxConfiguration = ({
 box,
 boxes,
 setBoxes,
 updateBox,
 handleOnDrag,
 updateBoxScale,
}) => {
 const mode = useSelector((state) => state.mode);

 return (
  <div className="bg-gray-50 rounded-md">
   {/* BOX HEADER */}
   <button
    onClick={() => {
     return updateBoxes(box.id, { show: !box.show });
     // return setBoxes(
     // 	boxes.map((w) => {
     // 		if (w.id !== box.id) return w;
     // 		return {
     // 			...w,
     // 			show: !w.show,
     // 		};
     // 	})
     // );
    }}
    className={`w-full px-4 py-2 flex justify-between items-center space-x-16 text-white text-md rounded-t-md
                            ${!box.show && "rounded-b-md"}
                            bg-${box.colour}-500
                            focus:outline-none focus:ring 
                            focus:ring-${box.colour}-400
                            ${!box.show && "opacity-25"} hover:opacity-75
                            transition duration-300
            `}
   >
    <span className="whitespace-pre">Box {box.id + 1}</span>
    <span
     className={`text-xs font-semibold
                                text-${box.colour}-200`}
    >
     {box.show ? "ON" : "OFF"}
    </span>
   </button>
   {/* BOX BODY */}
   {!box.show ? null : (
    <div className="flex flex-col space-y-1 px-4 pt-1 pb-4 text-gray-800">
     {mode === "atem" && (
      <SourcesDropdown
       selected={box.label}
       onSelect={(choice) => {
        updateBox(box.id, { label: choice });
       }}
      />
     )}
     <MoveSources
      x={box.position.x}
      y={box.position.y}
      xCalc={box.calculatedPosition?.x}
      yCalc={box.calculatedPosition?.y}
      onChange={(x, y) => {
       updateBox(box.id, {
        position: { x: parseFloat(x), y: parseFloat(y) },
       });
       setTimeout(() => {
        updateBoxScale(box.id);
       }, 100);
       //handleOnDrag(null, null, box.id, true, scale);
      }}
     />
     <SourcesScale
      scale={box.scale}
      onChange={(scale) => {
       updateBox(box.id, { scale: scale });
       //handleOnDrag(null, null, box.id, true, scale);
      }}
      onMouseUp={(e) => {
       updateBoxScale(box.id);
      }}
     />
     <span className="text-xs uppercase font-semibold text-gray-500 ">
      Crop
     </span>
     <div className=" border-b pb-2">
      <div className="flex items-center space-x-2">
       <SourcesCrop
        side={"Left"}
        crop={box.cropped.left}
        onChange={(crop) => {
         if (box.lrCropLinked) {
          updateBox(box.id, {
           cropped: { ...box.cropped, left: crop, right: crop },
          });
         } else {
          updateBox(box.id, {
           cropped: { ...box.cropped, left: crop },
          });
         }
        }}
       />
       {/* LINKED */}
       <button
        className={`hover:text-gray-900 h-5 flex items-center justify-center
       ${box.lrCropLinked ? "text-gray-700" : "text-gray-500"}
       `}
        onClick={() => {
         updateBox(box.id, {
          lrCropLinked: !box.lrCropLinked,
         });
        }}
       >
        <svg
         className="w-5 h-5 flex items-center justify-center"
         fill="none"
         stroke="currentColor"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M10.371 7.629a3 3 0 0 0-4.242 0l-3 3a3 3 0 1 0 4.242 4.242l.826-.826m-.568-3.674a3 3 0 0 0 4.242 0l3-3a3 3 0 0 0-4.242-4.242l-.825.825"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
         />
        </svg>
       </button>
       <SourcesCrop
        side={"Right"}
        crop={box.cropped.right}
        disabled={box.lrCropLinked}
        onChange={(crop) => {
         if (box.lrCropLinked) {
          updateBox(box.id, {
           cropped: { ...box.cropped, left: crop, right: crop },
          });
         } else {
          updateBox(box.id, {
           cropped: { ...box.cropped, right: crop },
          });
         }
        }}
       />
      </div>
      <div className="flex items-center space-x-2">
       <SourcesCrop
        side={"Top"}
        crop={box.cropped.top}
        onChange={(crop) => {
         if (box.tbCropLinked) {
          updateBox(box.id, {
           cropped: { ...box.cropped, top: crop, bottom: crop },
          });
         } else {
          updateBox(box.id, {
           cropped: { ...box.cropped, top: crop },
          });
         }
        }}
       />
       {/* LINKED */}
       <button
        className={`hover:text-gray-900 h-5 flex items-center justify-center
       ${box.tbCropLinked ? "text-gray-700" : "text-gray-500"}
       `}
        onClick={() => {
         updateBox(box.id, {
          tbCropLinked: !box.tbCropLinked,
         });
        }}
       >
        <svg
         className="w-5 h-5 flex items-center justify-center"
         fill="none"
         stroke="currentColor"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M10.371 7.629a3 3 0 0 0-4.242 0l-3 3a3 3 0 1 0 4.242 4.242l.826-.826m-.568-3.674a3 3 0 0 0 4.242 0l3-3a3 3 0 0 0-4.242-4.242l-.825.825"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
         />
        </svg>
       </button>
       <SourcesCrop
        side={"Bottom"}
        crop={box.cropped.bottom}
        disabled={box.tbCropLinked}
        onChange={(crop) => {
         if (box.tbCropLinked) {
          updateBox(box.id, {
           cropped: { ...box.cropped, top: crop, bottom: crop },
          });
         } else {
          updateBox(box.id, {
           cropped: { ...box.cropped, bottom: crop },
          });
         }
        }}
       />
      </div>
     </div>
     <span className="pt-1 text-xs uppercase font-semibold text-gray-500 ">
      Copy/Paste
     </span>
     <CopyFromTo box={box} />
    </div>
   )}
  </div>
 );
};

const sources = [
 {
  label: "Camera 1",
  name: "Camera1",
 },
 {
  label: "Camera 2",
  name: "Camera2",
 },
 {
  label: "Camera 3",
  name: "Camera3",
 },
 {
  label: "Camera 4",
  name: "Camera4",
 },
 {
  label: "Camera 5",
  name: "Camera5",
 },
 {
  label: "Camera 6",
  name: "Camera6",
 },
 {
  label: "Camera 7",
  name: "Camera7",
 },
 {
  label: "Camera 8",
  name: "Camera8",
 },
 {
  label: "Color 1",
  name: "Color1",
 },
 {
  label: "Color 2",
  name: "Color2",
 },
 {
  label: "Media Player 1",
  name: "MediaPlayer1",
 },
 {
  label: "Media Player 2",
  name: "MediaPlayer2",
 },
];

const SourcesDropdown = ({ selected, onSelect }) => {
 return (
  <div className="border-b pb-2">
   {/* <span className="text-xs uppercase font-semibold text-gray-500 ">
    SOURCE
   </span> */}
   {/* <select
    className="mt-1 w-full bg-gray-50 border border-gray-200 focus:outline-none focus:ring rounded px-1 py-1"
    value={selected}
    onChange={(e) => {
     return onSelect(e.target.value);
    }}
   >
    {sources.map((source) => {
     return (
      <option key={source.name} value={source.name}>
       {source.label}
      </option>
     );
    })}
   </select> */}
   <SourcesCombobox selected={selected} onSelect={onSelect} />
  </div>
 );
};

const MoveSources = ({ x, y, xCalc, yCalc, onChange }) => {
 const mode = useSelector((state) => state.mode);
 return (
  <div className="border-b pb-2">
   <span className="text-xs uppercase font-semibold text-gray-500 ">
    Position{" "}
    {mode === "atem" && <span className="text-xs text-gray-400">(ATEM)</span>}
   </span>
   <div className="flex items-center space-x-1">
    <div className="flex items-center">
     <span className="text-xs text-gray-500">x</span>
     <input
      type="number"
      value={x}
      step="1"
      onChange={(e) => {
       onChange(e.target.value, y);
      }}
      className="w-16 text-xs bg-gray-50 px-1 py-1 focus:outline-none focus:ring rounded"
     />
    </div>
    <div className="flex items-center">
     <span className="text-xs text-gray-500">y</span>
     <input
      type="number"
      value={y}
      step="1"
      onChange={(e) => {
       onChange(x, e.target.value);
      }}
      className="w-16 text-xs bg-gray-50 px-1 py-1 focus:outline-none focus:ring rounded"
     />
    </div>
   </div>

   {mode === "atem" && (
    <>
     <span
      style={{
       width: "2rem",
      }}
      className="text-xs text-gray-400 inline-block"
     >
      ({Math.round(xCalc * 100) / 100})
     </span>
     <span className="ml-9 text-xs text-gray-400">
      ({Math.round(yCalc * 100) / 100})
     </span>
    </>
   )}
  </div>
 );
};

const SourcesScale = ({ scale, onChange, onMouseUp }) => {
 return (
  <div className="border-b pb-2">
   <span className="text-xs uppercase font-semibold text-gray-500 ">Size</span>
   <input
    className="w-full"
    type="range"
    min="0"
    max="1"
    step="0.01"
    value={scale}
    onChange={(e) => {
     onChange(e.target.value);
    }}
    onMouseUp={(e) => {
     onMouseUp(e.target);
    }}
   />
   {/* <span className="text-xs">{scale}</span> */}
   <input
    type="number"
    value={scale}
    min="0"
    max="1"
    step="0.01"
    onChange={(e) => onChange(e.target.value)}
    className="text-xs bg-gray-50 px-1 py-1 focus:outline-none focus:ring rounded"
   />
  </div>
 );
};

const SourcesCrop = ({ side, crop, onChange, disabled = false }) => {
 return (
  <div
   className={`
  ${disabled && "opacity-50"}
  `}
  >
   <span className="text-xs text-gray-500 ">{side}</span>
   <input
    className="w-full"
    disabled={disabled}
    type="range"
    min="0"
    max="16"
    step="1"
    value={crop}
    onChange={(e) => {
     onChange(e.target.value);
    }}
   />
   {/* <span className="text-xs">{crop}</span> */}
   <input
    type="number"
    disabled={disabled}
    value={crop}
    min="0"
    max="16"
    step="1"
    onChange={(e) => onChange(e.target.value)}
    className="text-xs bg-gray-50 px-1 py-1 focus:outline-none focus:ring rounded"
   />
  </div>
 );
};

const CopyFromTo = ({ box }) => {
 const copyFromId = useSelector((state) => state.copyFrom);
 const boxes = useSelector((state) => state.boxes);

 const handlePaste = () => {
  console.log(boxes[copyFromId]);

  // UPDATE CURRENT BOX WITH COPIED ATTRIBUTES
  updateBoxes(box.id, {
   position: { ...boxes[copyFromId].position },
   calculatedPosition: { ...boxes[copyFromId].calculatedPosition },
   scale: boxes[copyFromId].scale,
   cropped: {
    ...boxes[copyFromId].cropped,
   },
  });
 };

 return (
  <div className="flex">
   <button
    // disabled={copyFromId === box.id}
    className={`flex px-2 py-1 text-sm border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring
        ${
         copyFromId === box.id || copyFromId !== null
          ? "opacity-50"
          : "opacity-100"
        }
        hover:opacity-100
        `}
    onClick={() => {
     if (copyFromId === box.id) {
      return setCopyFrom(null);
     } else {
      setCopyFrom(box.id);
     }
    }}
   >
    <svg
     className="w-5 h-5 flex items-center justify-center"
     fill="none"
     stroke="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path
      d="M6.75 3.75a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5m-4.5 0h-1.5a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 1.5 1.5h7.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-6Zm0 0a1.5 1.5 0 0 0 1.5 1.5h1.5a1.5 1.5 0 0 0 1.5-1.5h-4.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
     />
    </svg>
    {copyFromId === box.id ? "Copied" : "Copy"}
   </button>
   {copyFromId !== box.id && copyFromId !== null ? (
    <button
     disabled={copyFromId === box.id || copyFromId === null}
     className={`flex px-2 py-1 text-sm border-gray-200 text-gray-600 hover:bg-gray-100 transition duration-300 rounded focus:outline-none focus:ring
    ${
     copyFromId === box.id || copyFromId === null ? "opacity-50" : "opacity-100"
    }
        `}
     onClick={handlePaste}
    >
     <svg
      className="w-5 h-5 flex items-center justify-center"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
       d="m6.75 10.5 1.5 1.5 3-3m-4.5-5.25h-1.5a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 1.5 1.5h7.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-6Zm0 0a1.5 1.5 0 0 0 1.5 1.5h1.5a1.5 1.5 0 0 0 1.5-1.5h-4.5Zm0 0a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5h-4.5Z"
       stroke="currentColor"
       strokeWidth="2"
       strokeLinecap="round"
       strokeLinejoin="round"
      />
     </svg>
     Paste
    </button>
   ) : (
    ""
   )}
  </div>
 );
};

function Header() {
 return (
  <header className="bg-gray-800 py-2">
   <div className="max-w-3xl mx-auto flex justify-between items-center">
    <div className="flex flex-col items-start">
     <Link to="/">
      <h1>H2R Layouts</h1>

      <span className="text-sm text-gray-500">
       Designed for ATEM SuperSource and vMix
      </span>
     </Link>
    </div>
    <div className="flex space-x-2">
     <Link
      className="text-base text-gray-500 hover:text-gray-400"
      to="/support"
     >
      <span>Support this project</span>
     </Link>
     <span className="text-gray-500">|</span>
     <Link
      className="text-base text-gray-500 hover:text-gray-400"
      to="/tutorial"
     >
      <span>Tutorials</span>
     </Link>
    </div>
   </div>
  </header>
 );
}

function Tutorial() {
 return (
  <>
   <Header />
   <section className="mt-10 max-w-3xl  mx-auto">
    <FAQSection
     title="ATEM SuperSource"
     subtitle="Help to get you up and running with the SuperSource layouts."
     videoId="fpK0tBRQeiw"
    />
    <FAQSection
     title="ATEM Animated SuperSource"
     subtitle="Get started with adding animations to your ATEM SuperSource layouts."
     videoId="pHAchVX2NCA"
    />
    <FAQSection
     title="vMix Virtual Set"
     subtitle="Learn how to use H2R Layouts to create virtual sets for vMix."
     videoId="O3lSoDcg5wk"
    />
   </section>
   <Footer />
  </>
 );
}

function FAQSection({ title, subtitle, videoId }) {
 return (
  <div className="bg-white">
   <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-10 lg:px-8">
    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
     <div>
      <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
      <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
     </div>
     <div className="mt-12 lg:mt-0 lg:col-span-2">
      <dl className="space-y-12">
       <YouTube videoId={videoId} containerClassName={"youtubeContainer"} />
      </dl>
     </div>
    </div>
   </div>
  </div>
 );
}

function Support() {
 return (
  <>
   <Header />
   <section className="mt-10 max-w-3xl  mx-auto">
    <h2 className="text-3xl font-bold text-gray-900">Support this project</h2>
    <p className="mt-4 text-lg text-gray-500">
     Purchasing any of the packs below will keep this project going!
    </p>
    <PurchasePack mode="atem" link="" />
    <PurchasePack mode="atem-animated" />
    <PurchasePack mode="vmix" />
   </section>
   <Footer />
  </>
 );
}

function PurchasePack({ mode }) {
 let link;

 switch (mode) {
  case "atem":
   link =
    "https://heretorecord.lemonsqueezy.com/checkout/buy/d9121e07-72e4-4404-b540-218052a222c7";
   break;

  case "atem-animated":
   link =
    "https://heretorecord.lemonsqueezy.com/checkout/buy/17502530-7f09-4b9a-b1ba-a09f56e8894a";
   break;

  case "vmix":
   link =
    "https://heretorecord.lemonsqueezy.com/checkout/buy/d3425fc2-5dcb-441b-883f-bb3b48724b5d";
   break;

  default:
   break;
 }
 return (
  <section className="mt-10 flex flex-col max-w-3xl mx-auto   text-white rounded">
   <img
    className="border-t-2 border-l-2 border-r-2 border-gray-500 rounded-t"
    src={mode === "atem" ? packPreview : packPreviewVmix}
    alt="Preview of download pack"
   />
   <div className="px-6 py-4 flex w-full justify-between items-center  bg-gray-700 rounded-b">
    <div>
     <h2 className="text-white">
      {mode === "atem" && "100+ pre-made layouts, ready to use on your ATEM!"}
      {mode === "atem-animated" &&
       "100+ animated pre-made layouts, ready to use on your ATEM!"}
      {mode === "vmix" && "100+ pre-made layouts, ready to use in vMix!"}
     </h2>
     <p className="text-sm text-gray-300">
      I created lots of layouts so you don't have to.
     </p>
    </div>{" "}
    <a
     href={link}
     target="_blank"
     className="h-full px-3 py-2 text-xs cursor-pointer font-semibold bg-gray-800 text-gray-50 hover:bg-gray-600 transition duration-300 rounded focus:outline-none focus:ring"
    >
     {mode === "atem" && "Buy ATEM Pack"}
     {mode === "atem-animated" && "Buy Animated ATEM Pack"}
     {mode === "vmix" && "Buy vMix Packs"}
    </a>
   </div>
  </section>
 );
}

export default App;
